<template>
  <div class="d-flex flex-column">
    <v-btn
      color="accent"
      @click="generateData()"
    >
      Generate data
    </v-btn>
    <div class="d-flex flex-row flex-wrap">
      <user-chip
        v-for="(_, i) in users"
        :key="i+'chip'"
        :user="_"
      />

    </div>

  </div>

</template>

<script>
import _ from 'lodash'

export default {
  components: {
    UserChip: () => import('@/components/user-chip')
  },
  created () {
    this.generateData()
  },
  data () {
    return {
      users: []
    }
  },
  methods: {
    generateData () {
      this.users = []

      const names1 = ['Zara', 'Jaxx', 'Jeff', 'Peggy', 'Elaina', 'Shirley', 'Ria', 'Ben', 'Steve']
      const names2 = ['Kirkland', 'Carson', 'Rios', 'Perez', 'Shepard']
      const emails = ['abc.com', 'defg.org', 'hijklmnop.fr', 'qrstuv.ca']
      const types = [null, 'account-contacts']

      let i
      for (i = 0; i < 60; i++) {
        const name1 = _.sample(names1)
        const name2 = _.sample(names2)

        this.users.push({
          fullName: `${name1} ${name2}`,
          email: `${name1}.${name2}@${_.sample(emails)}`,
          type: _.sample(types)
        })
      }
    }
  }

}
</script>

<style lang="stylus" scoped></style>
